import dayjs from '@/utils/dayjs'

export default function(day, format = 'YYYY-MM-DD') {
  const inputFullDate = dayjs(day.shift(), format)
  const inputDate = inputFullDate.date()
  const inputDayNumber = inputFullDate.day()

  const rangeStart = inputFullDate.date(
    inputDayNumber === 0 ? inputDate - inputDayNumber - 6 : inputDate - inputDayNumber + 1
  )
  const rangeEnd = rangeStart.date(rangeStart.date() + 6)
  return [rangeStart, rangeEnd].map(itm => itm.format(format))
}
