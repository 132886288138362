<template>
  <v-sheet class="text-center py-10" max-width="500">
    <v-progress-circular :size="100" width="4" indeterminate color="primary" />
    <h4 class="loader-title mt-8">{{ title }}</h4>
  </v-sheet>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
  },
}
</script>

<style scoped>
.loader-title {
  font-size: 1.3em;
  font-weight: 400;
}
</style>
