<template>
  <v-dialog :max-width="pending ? 500 : 370" v-model="show">
    <template v-slot:activator="{on}">
      <v-btn color="primary" v-on="on" @click="open">{{ activatorName }}</v-btn>
    </template>
    <v-form
      v-if="!pending"
      :valid="valid"
      @submit.prevent="upload"
      @keyup.enter="upload"
      ref="form"
      lazy-validation
    >
      <v-card>
        <v-card-title class="py-6">
          <h6 class="text-h6 mx-auto">{{ title }}</h6>
        </v-card-title>
        <v-card-text>
          <v-file-input
            prepend-inner-icon="mdi-paperclip"
            :prepend-icon="false"
            :rules="[...$rules.required]"
            label="Dodaj plik"
            outlined
            dense
            :accept="formats"
            v-model="file"
          />
          <slot />
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-5">
          <v-spacer />
          <v-btn elevation="0" @click="close">Anuluj</v-btn>
          <v-btn color="primary" type="submit" :loading="pending">Załaduj</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <Loader title="Trwa import produktów" v-else />
  </v-dialog>
</template>

<script>
import Loader from '@/components/UI/Loader'

export default {
  components: {
    Loader,
  },
  props: {
    activatorName: {
      type: String,
      default: 'Załaduj ręcznie',
      required: false,
    },
    pending: {
      type: Boolean,
      default: false,
      required: false,
    },
    formats: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      show: false,
      valid: true,
      file: null,
    }
  },
  methods: {
    open() {
      this.show = true
    },
    close() {
      this.show = false
      this.file = null
    },
    upload() {
      if (this.$refs.form.validate()) {
        const formData = new FormData()
        formData.append('file', this.file)
        this.$emit('uploadedFile', formData)
      }
    },
  },
}
</script>

<style></style>
