const alertPresets = {
  success: {
    title: 'Raport został załadowany',
    icon: 'success',
    timer: 3000,
    showConfirmButton: false,
  },
  error: {
    title: 'Coś poszło nie tak',
    icon: 'error',
    timer: 3000,
    showConfirmButton: false,
  },
}
export default alertPresets
